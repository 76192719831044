import React from 'react';
import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { VoteBox } from '@logora/parliament.vote.vote_box';
import { StatBox } from '@logora/parliament.ui.stat_box';
import {
    QuestionIcon,
    SpeechIcon,
    DocumentIcon
} from '@logora/parliament.icons.regular_icons';
import useFetchStats from '../stats/useFetchStats';
import useFetchVotes from '../votes/useFetchVotes';

export const ActeurDashboard = ({ acteur }) => {
    const dataProvider = useDataProvider();
    const { stats, loading: statsLoading, error: statsError } = useFetchStats(dataProvider);
    const { votes, loading: votesLoading, error: votesError } = useFetchVotes(dataProvider, { acteurRefUid: acteur.uid });
    if (!statsLoading && !statsError && Object.keys(stats).length > 0) {
        return (
            <>
                <Box
                    display="flex"
                    flexDirection="row"
                    marginTop="2em"
                    gap="2em"
                    width="100%"
                >
                    <StatBox
                        icon={<DocumentIcon />}
                        mainStat={acteur.nombreAmendements}
                        text="amendements déposés"
                        average={stats.amendements?.moyenne ?? 0}
                        maxValue={stats.amendements?.ecartMoyenneMax ?? 0}
                    />
                    <StatBox
                        icon={<SpeechIcon />}
                        mainStat={acteur.nombreInterventions}
                        text="interventions en hémicycle"
                        average={stats.interventions?.moyenne ?? 0}
                        maxValue={stats.interventions?.ecartMoyenneMax ?? 0}
                    />
                    <StatBox
                        icon={<QuestionIcon />}
                        mainStat={acteur.nombreQuestions}
                        text="questions posées"
                        average={stats.questions?.moyenne ?? 0}
                        maxValue={stats.questions?.ecartMoyenneMax ?? 0}
                    />
                </Box>
                <Box sx={{ width: '100%', marginTop: '2em' }}>
                    <UiBox title='Derniers votes' visibleCount={3}>
                        { votes.map(vote => {
                            return <VoteBox date={vote.dateVote} votePosition={vote.positionVote} mainText={vote.scrutinRef?.titre} status={vote.scrutinRef?.code} statusHighlighted />
                        })}
                    </UiBox>
                </Box>
            </>
        );
    }
};
