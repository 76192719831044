import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TableSortLabel,
    TablePagination,
    Paper,
    Drawer
} from '@mui/material';
import { useTranslate, useDataProvider } from 'react-admin';
import { FilterBar } from './FilterBar';
import { SearchBar } from './SearchBar';
import { visuallyHidden } from '@mui/utils';
import styles from './List.module.scss';
import { AmendementShow } from '../amendements/AmendementShow';

export const List = ({
    dataset,
    perPage = 10,
    Children,
    search,
    globalFilters = [],
    display = false,
    showSearch = false,
    navigateOnChange = false,
    onUpdateTotal,
    title
}) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(search);
    const [total, setTotal] = useState(0);
    const [drawerItem, setDrawerItem] = useState(null);
    const params = useParams();

    function findFirstObjectWithSortKey(obj) {
        const entry = Object.entries(obj).find(
            ([_, value]) => value?.defaultSort !== undefined
        );
        return entry ? entry[0] : Object.keys(obj)[0] || {};
    }

    const getDefaultSort = () => {
        const isPreSorted =
            params.dataset === dataset.key && searchParams.get('sort') !== null;

        if (isPreSorted && navigateOnChange) {
            return {
                field: searchParams.get('sort'),
                order: searchParams.get('order').toLowerCase(),
                name: searchParams.get('sort')
            };
        }

        if (dataset.fields === undefined) return {};

        const fields = dataset.fields;
        const firstFieldKey = findFirstObjectWithSortKey(fields);

        return {
            field: fields[firstFieldKey]['sort'] || undefined,
            order: fields[firstFieldKey]['order'] || 'desc',
            name: firstFieldKey
        };
    };

    const getDefaultFilters = () => {
        if (dataset) {
            const initFilters = dataset.filters || [];
            initFilters.map((f) => {
                if (navigateOnChange && searchParams.get(f.field) !== null) {
                    f.value = searchParams.get(f.field);
                } else {
                    f.value = f.defaultValue || null;
                }
            });
            return initFilters;
        }
    };

    const getDefaultPage = () => {
        const isPrePaginated =
            params.dataset === dataset.key && searchParams.get('page');

        return navigateOnChange && isPrePaginated
            ? parseInt(searchParams.get('page'))
            : 1;
    };

    const [sort, setSort] = useState(getDefaultSort());
    const [filters, setFilters] = useState(getDefaultFilters());
    const [page, setPage] = useState(getDefaultPage());
    const [data, setData] = useState([]);

    const handleFilterChange = (filter, newValue) => {
        setFilters((prevFilters) =>
            prevFilters.map((f) =>
                filter.field === f.field ? { ...f, value: newValue } : f
            )
        );
    };

    const handleRowClick = (item) => {
        if (dataset.key === "amendements") {
            const path = `/parliament/${dataset.key}/${item.uid || item.id}`;
            setDrawerItem(item);
            window.history.pushState({}, '', path);
        } else if (dataset.rowRedirect) {
            navigate(dataset.rowRedirect(item));
        } else {
            navigate(`/parliament/${dataset.key}/${item.uid || item.id}`);
        }
    };

    const handleDrawerClose = () => {
        setDrawerItem(null);
        window.history.pushState({}, '', location.pathname);
    }

    const handleSort = (key) => {
        const sortField = dataset.fields[key].sort || dataset.fields[key].field;

        setSort((prevSort) => {
            const order =
                prevSort.name === key && prevSort.order === 'desc'
                    ? 'asc'
                    : 'desc';
            return { field: sortField, order: order, name: key };
        });

        if (navigateOnChange) {
            setSearchParams((params) => {
                params.set('sort', sortField);
                params.set(
                    'order',
                    sort.name === key && sort.order === 'desc' ? 'ASC' : 'DESC'
                );
                return params;
            });
        }
    };

    const mapObjectKeys = (originalObject, keyMapping = {}) => {
        const mappedObject = {};
        for (const key in originalObject) {
            if (originalObject.hasOwnProperty(key)) {
                const newKey = keyMapping[key] || key;
                mappedObject[newKey] = originalObject[key];
            }
        }
        return mappedObject;
    };

    const fetchData = () => {
        setLoading(true);
        const dataFilters = {
            ...filters?.reduce((acc, filter) => {
                if (filter.value !== null) {
                    acc[filter.field] = filter.value;
                }
                return acc;
            }, {}),
            ...mapObjectKeys(globalFilters, dataset.filterMapping)
        };

        dataProvider
            .getList(dataset.key, {
                filter: dataFilters,
                pagination: { page: page, perPage: perPage },
                include: Object.values(dataset.fields || {}).reduce(
                    (acc, current) => {
                        if (current.hasOwnProperty('include')) {
                            acc.push(current.include);
                        }
                        return acc;
                    },
                    []
                ),
                sort: sort,
                search: searchQuery,
                ...(dataset.searchTable && {
                    searchTable: dataset.searchTable
                }),
                ...(dataset.distinct && { distinct: dataset.distinct })
            })
            .then((res) => {
                if (res) {
                    setData(res.data);
                    setTotal(res.total);
                    if (onUpdateTotal) {
                        onUpdateTotal(dataset.key, res.total);
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(`Failed to fetch ${dataset.key} data:`, error);
                setLoading(false);
            });
    };

    const fetchTotal = () => {
        const dataFilters = {
            ...filters?.reduce((acc, filter) => {
                if (filter.value !== null) {
                    acc[filter.field] = filter.value;
                }
                return acc;
            }, {}),
            ...mapObjectKeys(globalFilters, dataset.filterMapping)
        };

        dataProvider
            .getList(dataset.key, {
                sort: {},
                pagination: { page: 1, perPage: 1 },
                filter: dataFilters,
                include: Object.values(dataset.fields || {}).reduce(
                    (acc, current) => {
                        if (current.hasOwnProperty('include')) {
                            acc.push(current.include);
                        }
                        return acc;
                    },
                    []
                ),
                search: search,
                ...(dataset.searchTable && { searchTable: dataset.searchTable })
            })
            .then((res) => {
                if (res.total) {
                    setTotal(res.total);
                    if (onUpdateTotal) {
                        onUpdateTotal(dataset.key, res.total);
                    }
                }
            })
            .catch((error) =>
                console.error(`Failed to fetch ${dataset.key} total:`, error)
            );
    };

    useEffect(() => {
        setSearchQuery(search);
    }, [search]);

    useEffect(() => {
        if (display) {
            fetchData();
        }
    }, [page, sort, display, searchQuery, filters, globalFilters]);

    useEffect(() => {
        if (!display) {
            fetchTotal();
        }
    }, [searchQuery, display, globalFilters]);

    useEffect(() => {
        if (display && navigateOnChange) {
            const filterObj = filters.reduce((acc, f) => {
                if (f.value !== null) acc[f.field] = f.value;
                return acc;
            }, {});

            if (search) filterObj['query'] = search;

            const queryString = new URLSearchParams({
                ...filterObj
            });

            if (sort.field) {
                queryString.set('sort', sort.field);
                queryString.set('order', sort.order);
            }
            if (page > 1) queryString.set('page', page);

            navigate({
                pathname: `/parliament/recherche/${dataset.key}`,
                search: queryString.toString()
            });
        }
    }, [display]);

    if (!display) return null;

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.actionBar}>
                    {filters.length > 0 &&
                        <FilterBar
                            filters={filters}
                            onFilterChange={handleFilterChange}
                            title={title}
                        />
                    }
                    {showSearch && (
                        <SearchBar
                            onSearchChange={(query) => setSearchQuery(query)}
                            styles={{
                                width: 'max-content !important'
                            }}
                            size="small"
                        />
                    )}
                </div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: '100%', minHeight: '40em' }}
            >
                <Table>
                    {loading ? (
                        <div className={styles.loader}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {!Children && (
                                <TableHead>
                                    {data.length > 0 && (
                                        <TableRow
                                            className={styles.tableHeader}
                                        >
                                            {Object.keys(dataset.fields).map(
                                                (key) => (
                                                    <TableCell
                                                        key={
                                                            dataset.fields[key]
                                                                .field
                                                        }
                                                        className={
                                                            styles.tableHeaderCell
                                                        }
                                                        sortDirection={
                                                            sort.name === key
                                                                ? sort.order
                                                                : false
                                                        }
                                                    >
                                                        <TableSortLabel
                                                            active={
                                                                sort.name ===
                                                                key
                                                            }
                                                            direction={
                                                                sort.order
                                                            }
                                                            onClick={() =>
                                                                handleSort(key)
                                                            }
                                                        >
                                                            {
                                                                dataset.fields[
                                                                    key
                                                                ].label
                                                            }
                                                            {sort.name ===
                                                                key ? (
                                                                <Box
                                                                    component="span"
                                                                    sx={
                                                                        visuallyHidden
                                                                    }
                                                                >
                                                                    {sort.order ===
                                                                        'desc'
                                                                        ? 'sorted descending'
                                                                        : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                )
                                            )}
                                        </TableRow>
                                    )}
                                </TableHead>
                            )}
                            {Children &&
                                data.map((item, rowIndex) => (
                                    <Children
                                        key={rowIndex}
                                        resource={item}
                                        index={rowIndex}
                                    />
                                ))}
                            {!Children && (
                                <TableBody className={styles.tableBody}>
                                    {data.length === 0 ? (
                                        <TableRow className={styles.emptyList}>
                                            <TableCell>
                                                Pas de résultats
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        data.map((item, rowIndex) => (
                                            <TableRow
                                                key={rowIndex}
                                                className={styles.hoverRow}
                                                onClick={() =>
                                                    handleRowClick(item)
                                                }
                                            >
                                                {Object.keys(
                                                    dataset.fields
                                                ).map((key) => (
                                                    <TableCell
                                                        key={
                                                            dataset.fields[key]
                                                                .field
                                                        }
                                                        className={
                                                            styles.tableCell
                                                        }
                                                    >
                                                        {dataset.fields[key]
                                                            .format
                                                            ? dataset.fields[
                                                                key
                                                            ].format(
                                                                dataset
                                                                    .fields[
                                                                    key
                                                                ].field,
                                                                item
                                                            )
                                                            : item[
                                                                dataset
                                                                    .fields[
                                                                    key
                                                                ].field
                                                            ]?.toString() ||
                                                            '-'}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            )}
                            {data.length > 0 && (
                                <TableFooter>
                                    <TableRow
                                        align="left"
                                        className={styles.filterRow}
                                    >
                                        <TablePagination
                                            page={page - 1}
                                            count={total}
                                            labelRowsPerPage={translate(
                                                'pos.main.rows_per_page'
                                            )}
                                            rowsPerPage={perPage}
                                            onPageChange={(_, page) => {
                                                setPage(page + 1);
                                                setSearchParams((params) => {
                                                    params.set(
                                                        'page',
                                                        page + 1
                                                    );

                                                    return params;
                                                });
                                            }}
                                            labelDisplayedRows={({
                                                from,
                                                to,
                                                count
                                            }) => {
                                                return `${from}-${to} ${translate(
                                                    'pos.main.from_to_pagination'
                                                )} ${count}`;
                                            }}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </>
                    )}
                </Table>
            </TableContainer>
            <Drawer
                open={!!(drawerItem)}
                anchor="right"
                sx={{ zIndex: 100 }}
                onClose={handleDrawerClose}
            >
                <AmendementShow amendement={drawerItem} />
            </Drawer>
        </div>
    );
};
