import React, { useEffect, useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { Show } from '../components/show/Show';
import { DebatDetails } from '../debats';
import { participantsReunionsData } from '../participants_reunions';
import { MultiList } from '../search/MultiList';
import { Label } from '@logora/parliament.ui.label';
import { Box } from '@logora/parliament.ui.box';
import { formatDateTime, formatOrgane } from '../formatters';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { SenatHemicycle } from '@logora/parliament.parliament_chamber.senat_hemicycle';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import Tabs from '../components/Tabs';
import styles from './ReunionShow.module.scss';
import cloneDeep from 'lodash/cloneDeep';

const ReunionDetails = ({ resource }) => {
    const dataProvider = useDataProvider();
    const [participants, setParticipants] = useState([]);
    const [orateurs, setOrateurs] = useState([]);

    useEffect(() => {
        if (resource?.nombreParticipants > 0) {
            fetchParticipants();
        }
        if (resource.compteRenduRef) {
            fetchOrateurs();
        }
    }, [resource]);

    const fetchOrateurs = useCallback(async () => {
        try {
            const response = await dataProvider.getList('interventions', {
                filter: { debatRefUid: resource.compteRenduRefUid },
                include: ['acteurRef.mandatPrincipal', 'acteurRef.groupeParlementaire'],
                distinct: 'acteurRefUid',
                pagination: { perPage: 1000 }
            });
            const orateurs = response.data;
            if (orateurs) {
                setOrateurs(orateurs);
            }
        } catch (error) {
            console.log(error);
        }
    });

    const fetchParticipants = useCallback(async () => {
        try {
            const response = await dataProvider.getList('participants_reunions', {
                filter: { agendaRefUid: resource.uid },
                include: ['acteurRef.mandatPrincipal', 'acteurRef.groupeParlementaire'],
                pagination: { perPage: 1000 }
            });
            const participants = response.data;
            if (participants) {
                setParticipants(participants);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    const participantsFilters = participantsReunionsData.filters.concat([
        { field: 'agendaRefUid', hidden: true, defaultValue: resource.uid, type: 'text' }
    ]);

    const participantsDataset = {
        participants_reunions: Object.assign(cloneDeep(participantsReunionsData), { filters: participantsFilters })
    };

    const getHighlightedSeats = () => {
        let membres = [];
        if (orateurs?.length) {
            membres = orateurs;
        } else if (participants?.length) {
            membres = participants;
        }
        return membres.map((m) => {
            return {
                id: parseInt(m?.acteurRef?.placeHemicycle || m?.placeHemicycle),
                color: m?.acteurRef?.groupeParlementaire?.couleurAssociee || '#777',
                labelBackgroundColor: '#FFF',
                label: (
                    <Box className={styles.labelHemicycle}>
                        <MemberBox
                            firstName={m?.acteurRef?.prenom}
                            lastName={m?.acteurRef?.nom}
                            imageUrl={m?.acteurRef?.urlImage}
                            parliamentGroup={{
                                color: m?.acteurRef?.groupeParlementaire?.couleurAssociee,
                                groupName: m?.acteurRef?.groupeParlementaire?.libelle
                            }}
                        />
                    </Box>
                ),
                position: 'top'
            };
        });
    };

    const getCodeClassName = (code) => {
        switch (code) {
            case 'Annulé':
                return 'cancelled';
            case 'Confirmé':
                return 'confirmed';
            default:
                return 'undefined';
        }
    };

    const getTabData = () => {
        let tabData = [];
        if (resource.compteRenduRef) {
            tabData.push({
                label: 'Compte-rendu',
                content: (
                    <div role="tabpanel" value={0} index={0}>
                        <DebatDetails resource={resource.compteRenduRef} />
                    </div>
                )
            });
        }
        if (resource.nombreParticipants > 0) {
            tabData.push({
                label: 'Participants',
                content: (
                    <div role="tabpanel" value={1} index={1}>
                        <MultiList datasets={participantsDataset} showListSearch={true} navigateOnChange={false} />
                    </div>
                )
            });
        }
        return tabData;
    };

    return (
        <>
            <div className={styles.header}>
                <ChamberLabel chamber={resource.chambre} />
                <Label text={'Réunion'} />
            </div>
            <div className={styles.title}>
                {resource.resumeOdj?.length
                    ? resource.resumeOdj.join('\n')
                    : resource.compteRenduRef?.quantiemesJournee}
            </div>
            <div className={styles.presentation}>
                <div className={styles.left}>
                    <div className={styles.box}>
                        <Box title="Organe" className={styles.boxContainer}>
                            <div>{formatOrgane('organeReunionRef', resource)}</div>
                        </Box>
                    </div>
                    <div className={styles.box}>
                        <Box title="Date" className={styles.boxContainer}>
                            <div>{formatDateTime('timestampDebut', resource)}</div>
                        </Box>
                    </div>
                    <div className={styles.box}>
                        <Box title="État de la réunion" className={styles.boxContainer}>
                            <Label className={styles[`${getCodeClassName(resource.etat)}`]} text={resource.etat} />
                        </Box>
                    </div>
                    <div className={styles.box}>
                        <Box
                            title={resource.compteRenduRef ? "Nombre d'orateurs" : 'Nombre de participants'}
                            className={styles.boxContainer}
                        >
                            <div>
                                {resource.compteRenduRef
                                    ? `${orateurs.length} orateurs`
                                    : `${resource.nombreParticipants} participants`}
                            </div>
                        </Box>
                    </div>
                </div>
                <div className={styles.box}>
                    <Box title={resource.compteRenduRef ? 'Orateurs' : 'Participants'} className={styles.boxContainer}>
                        {resource.chambre === 'AN' && (
                            <AssembleeHemicycle
                                className={styles.svgIcon}
                                highlightedSeats={getHighlightedSeats(
                                    resource.compteRenduRef && orateurs ? orateurs : participants
                                )}
                            />
                        )}

                        {resource.chambre === 'SN' && (
                            <SenatHemicycle
                                className={styles.svgIcon}
                                highlightedSeats={getHighlightedSeats(
                                    resource.compteRenduRef && orateurs ? orateurs : participants
                                )}
                            />
                        )}
                    </Box>
                </div>
            </div>
            <div className={styles.corps}>
                <div className={styles.tabContainer}>
                    <div>
                        <Tabs tabData={getTabData()} />
                    </div>
                </div>
            </div>
        </>
    );
};

export const ReunionShow = () => {
    return (
        <Show
            resource={'reunions'}
            includes={['compteRenduRef', 'organeReunionRef']}
            backLink={'/parliament/recherche/reunions'}
            backText={'Voir toutes les réunions'}
        >
            <ReunionDetails />
        </Show>
    );
};
